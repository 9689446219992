import styled from "styled-components";
import {BorderRadius, caption, container, grid, h5, mediaQueries} from "@theme/lib";

// Quote block
export const StyledQuoteBlock= styled.div`
  .quote__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.testimonial.quote.background};
    ${(props) => BorderRadius(props.theme.config.componentRadius)}

    flex-flow: column;
    gap: 8px;
    padding: 16px 24px 24px 24px;
    ${mediaQueries.md} {
      gap: 24px;
      flex-flow: row;
      padding: 48px 48px 48px 36px;
    }
  }

  figure {
    position: relative;
    padding-top: 2.15rem;
    ${mediaQueries.md} {
      padding-top: 0;
      padding-left: 3.5rem;
    }
    &::before {
      content: " ";
      background-color: ${(props) => props.theme.colors.testimonial.quote.quoteIcon};
      mask-image: url(/TestimonialBlockQuote/quote.svg);
      position: absolute;
      top: 0;
      left: 0;
      min-width: 32px;
      min-height: 25px;
      width: 32px;
      height: 25px;
    }
  }

  blockquote {
    ${h5}
    margin-bottom: 16px;
    color: ${(props) => props.theme.colors.testimonial.quote.text};
  }

  figcaption {
    ${caption}
    color: ${(props) => props.theme.colors.testimonial.quote.text};
  }
`;

// Block types - Single/Multiple
export const StyledTestimonialBlockQuote = styled.div`
  h2 {
    margin-bottom: 18px;
    color: ${(props) => props.theme.colors.testimonial.title};
  }
`;

export const StyledSingleTestimonialBlockQuote = styled(StyledTestimonialBlockQuote)`
  .grid__container {
    ${container}
    ${grid}
    padding-left: 52px;
    padding-right: 52px;
    margin-bottom: 3rem;
    ${mediaQueries.md} {
      padding: 0 1rem;
    }
  }
  .grid__wrapper {
    grid-column: 1 / span 5;
    ${mediaQueries.md} {
      grid-column: 2 / span 3
    }
    ${mediaQueries.lg} {
      grid-column: 3 / span 4;
    }
  }

  h2 {
    text-align: center;
    ${mediaQueries.md} {
      text-align: left;
      margin-bottom: 32px;
    }
  }
`;

export const StyledMultipleTestimonialBlockQuote = styled(StyledTestimonialBlockQuote)`
  h2 {
    ${mediaQueries.md} {
      margin-bottom: 24px;
    }
  }
`;


// Slider
// Todo: when we have time lets move slider as re-usable component in future.
export const StyledSlider = styled.div`
  grid-column: 1 / span 5;
  width: 100%;

  h2 {
    margin-bottom: 1.625rem;
    ${mediaQueries.lg} {
      margin-bottom: 1.5rem;
    }
  }

  .swiper {
    overflow: visible;
  }

  li {
    list-style: none;
  }

  ${mediaQueries.lg} {
    grid-column: 1 / span 10;
  }

  .swiper-slide {
    height: auto;
    width: 256px;

    ${mediaQueries.md} {
      width: 590px;
    }
    > div {
      height: 100%;
      > div {
        box-sizing: border-box;
        height: 100%;
      }
    }
  }
`;
export const StyledSliderTopWrapper = styled.div`
  ${mediaQueries.md} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
export const StyledSliderArrows = styled.div`
  display: none;
  gap: 1.75rem;
  margin-bottom: 1.18rem;

  button {
    background: none;
    border: none;
    height: auto;
    width: auto;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  .swiper {
    &__next, &__prev {
      svg {
        circle {
          fill: ${(props) => props.theme.colors.testimonial.slider.paginationArrowCircle};
        }
        path {
          fill: ${(props) => props.theme.colors.testimonial.slider.paginationArrowPath};
        }
      }
    }
  }

  button.swiper-button-disabled {
    opacity: 0.5;
    cursor: default;
  }

  img {
    display: block;
  }

  ${mediaQueries.md} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const StyledSliderPagination = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1.5rem;

  span {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    border-radius: ${(props) => props.theme.key === 'zsl' ? '0.25rem' : '50%'};
    opacity: 0.2;
    background-color: ${(props) => props.theme.colors.testimonial.slider.paginationBullet};
    margin: 0 !important;

    ${mediaQueries.lg} {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  span.swiper-pagination-bullet-active {
    opacity: 1;
    background-color: ${(props) => props.theme.colors.testimonial.slider.paginationBulletActive};
  }

  ${mediaQueries.lg} {
    gap: 0.75rem;
    margin-top: 2.3rem;
  }
`;
